<template>
  <div>
    <v-container grid-list-xl fluid>
      <header-title title="Descargar certificado"></header-title>
      <div v-if="convocatoria.id > 0">
        <v-row>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <p class="subtitle-1 font-weight-bold">
                  CONVOCATORIA PÚBLICA ESFM/UA - N° 001/2023 <br/>
                  CONVOCATORIA PÚBLICA UP - N° 001/2023
                  <!-- {{ convocatoria.cite }} -->
                </p>
                <p>
                  El/la postulante podrá descargar el
                  <b>certificado de no contar con procesos administrativos</b>.
                </p>
                <notify-text-ci></notify-text-ci>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mb-1">
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-form ref="formp" @submit.prevent="searchPersonal()">
                  <v-row class="mt-1">
                    <v-col cols="12" sm="4">
                      <v-text-field
                        v-model.trim="personal.cedula_dni"
                        label="Cédula de identidad"
                        :rules="[(v) => !!v || 'El campo es requerido']"
                        hint="1234567 o 1234567-1F"
                        clearable
                        filled
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-btn
                        type="submit"
                        large
                        color="primary"
                        :loading="load_search"
                      >
                        <v-icon>mdi-magnify</v-icon>
                        Buscar
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <div v-if="type_state == 2">
                  <h4 class="grey--text"></h4>
                  <v-row>
                    <v-col cols="12">
                      <p>
                        La Persona con Cédula de Identidad
                        {{ personal.cedula_dni }} ingresada, {{ personal.proceso }} evidencia
                        Procesos Administrativos con Resolución Final
                        Sancionatoria.
                      </p>
                      <p>
                        Puede descargar el certificado en el siguiente enlace:
                        
                      </p>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="text-center">
                      <v-btn
                        type="button"
                        large
                        color="info"
                        @click="generateCertificate"
                      >
                        Generar certificado
                      </v-btn>
                    </v-col>
                  </v-row>
                </div>
                <div v-else-if="type_state === 3">
                  <span class="red--text">
                    No hay registro con <b>Cédula de Identidad</b> ingresada.
                  </span>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else>
        <notify-card
          :title="notify_title"
          :load="notify_load"
          process="compulsa de méritos profesionales"
        ></notify-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import HeaderTitle from "@/components/HeaderTitle";
import NotifyCard from "@/components/NotifyCard";
import NotifyTextCi from "@/components/NotifyTextCi";
import Service from "../services/request";
import PDFService from "../utils/pdf";
import { mapActions } from "vuex";

export default {
  name: "certified-process",
  components: {
    HeaderTitle,
    NotifyCard,
    NotifyTextCi,
  },
  data: () => ({
    notify_title: "Cargando",
    notify_load: true,
    load_search: false,
    type_state: 1,
    convocatoria: {
      id: null,
      cite: "",
      descripcion: "",
      gestion: "",
    },
    generos: [],
    personal: {
      id: "",
      nombre: "",
      cedula_dni: "",
      proceso: "",
      observacion: "",
    },
  }),
  created() {
    this.getAnnouncement();
  },
  methods: {
    ...mapActions(["toast"]),
    getAnnouncement() {
      this.notify_load = true;
      Service.svcCertifiesAnnouncement()
        .then((response) => {
          this.convocatoria = response.data;
          this.notify_title = "Notificación";
          this.notify_load = false;
        })
        .catch(() => {
          this.notify_title = "Notificación";
          this.notify_load = false;
        });
    },
    searchPersonal() {
      if (this.$refs.formp.validate()) {
        this.load_search = true;
        this.type_state = 1;
        Service.svcProcessCertificate({
          convocatoria_id: this.convocatoria.id,
          cedula_dni: this.personal.cedula_dni,
        })
          .then((response) => {
            this.load_search = false;
            if (response.data.id == null) {
              this.type_state = 3;
            } else {
              this.type_state = 2;
              this.personal = response.data;
            }
          })
          .catch(() => {
            this.type_state = 3;
            this.load_search = false;
            console.error("Personal no encontrado");
          });
      } else {
        this.toast({ color: "info", text: "Ingrese Cédula de identidad" });
      }
    },
    generateCertificate() {
      PDFService.certifiedGenerateCertificate(this.convocatoria, this.personal);
    },
  },
};
</script>
